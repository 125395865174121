export const formatTableData = (data, currency, percent) => {
    if (data) {
      if (percent) {
        return `${data}%`;
      } else if (currency) {
        return `$${data}`;
      } else {
        return data;
      }
    } else {
      return 'N/A';
    }
  }