import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/BusinessSummaryDash.css";
import "../styles/PerformanceDash.css";
import TopBar from "../components/TopBar";
import { fetchUserDetails } from "../api/user";
import {
  fetchPerformanceKpiMetrics,
  fetchKpiMetricsByDriver,
  fetchKpiMetricsByMerchant,
  fetchKpiMetricsByTeam,
  fetchOptions,
} from "../api/performance";
import { CustomMultiSelect } from "../components/common/Dropdowns";
import { CustomPieChartPerformance } from "../components/performance/CustomPieChart";
import { CustomTables } from "../components/performance/CustomTables";
import { Loading } from "../components/common/Loading";

export const Performance = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [kpiMetrics, setKpiMetrics] = useState({});
  const [kpiMetricsLoading, setKpiMetricsLoading] = useState(true);

  const [kpiMetricsByMerchant, setKpiMetricsByMerchant] = useState([]);
  const [merchantTableTotal, setMerchantTableTotal] = useState(0);
  const [merchantTablePage, setMerchantTablePage] = useState(1);
  const [merchantTableLoading, setMerchantTableLoading] = useState(true);

  const [kpiMetricsByTeam, setKpiMetricsByTeam] = useState([]);
  const [teamTableTotal, setTeamTableTotal] = useState(0);
  const [teamTablePage, setTeamTablePage] = useState(1);
  const [teamTableLoading, setTeamTableLoading] = useState(true);

  const [kpiMetricsByDriver, setKpiMetricsByDriver] = useState([]);
  const [driverTableTotal, setDriverTableTotal] = useState(0);
  const [driverTablePage, setDriverTablePage] = useState(1);
  const [driverTableLoading, setDriverTableLoading] = useState(true);

  const [merchantOptions, setMerchantOptions] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);

  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);

  const { user } = useContext(AuthContext);

  const data = [
    { value: 60 }, // Percentage of completion (e.g., 60% of the gauge filled)
    { value: 40 }, // Remaining value to complete 100%
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const cx = 150;
  const cy = 100;
  const iR = 80;
  const oR = 100;
  const value = 50;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setKpiMetricsLoading(true);
    fetchPerformanceKpiMetrics(
      token,
      setKpiMetrics,
      navigate,
      {
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
        merchants: selectedMerchants.map((merchant) => merchant.value),
        teams: selectedTeams.map((team) => team.value),
        drivers: selectedDrivers.map((driver) => driver.value),
      },
      setKpiMetricsLoading,
      user.tenant_id
    );

    fetchOptions(
      token,
      setMerchantOptions,
      setTeamOptions,
      setDriverOptions,
      navigate,
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0],
      user.tenant_id
    );

    setMerchantTablePage(1);
    setTeamTablePage(1);
    setDriverTablePage(1);
    loadTableData(1, "team");
    loadTableData(1, "merchant");
    loadTableData(1, "driver");
  }, [startDate, endDate, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setKpiMetricsLoading(true);
    fetchPerformanceKpiMetrics(
      token,
      setKpiMetrics,
      navigate,
      {
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
        merchants: selectedMerchants.map((merchant) => merchant.label),
        teams: selectedTeams.map((team) => team.label),
        drivers: selectedDrivers.map((driver) => driver.value),
      },
      setKpiMetricsLoading,
      user.tenant_id
    );
  }, [selectedMerchants, selectedTeams, selectedDrivers]);

  const loadTableData = (page, type) => {
    const token = localStorage.getItem("accessToken");
    if (type === "team") {
      setTeamTableLoading(true);
      setTeamTablePage(page);
      fetchKpiMetricsByTeam(
        token,
        setKpiMetricsByTeam,
        setTeamTableTotal,
        navigate,
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        page,
        setTeamTableLoading,
        user.tenant_id
      );
    } else if (type === "merchant") {
      setMerchantTableLoading(true);
      setMerchantTablePage(page);
      fetchKpiMetricsByMerchant(
        token,
        setKpiMetricsByMerchant,
        setMerchantTableTotal,
        navigate,
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        page,
        setMerchantTableLoading,
        user.tenant_id
      );
    } else {
      setDriverTableLoading(true);
      setDriverTablePage(page);
      fetchKpiMetricsByDriver(
        token,
        setKpiMetricsByDriver,
        setDriverTableTotal,
        navigate,
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        page,
        setDriverTableLoading,
        user.tenant_id
      );
    }
  };

  return (
    <div className="dashboard-content">
      <h1>Performance</h1>
      <div className="date-picker-container">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="yyyy-MM-dd"
          className="date-picker"
          disabled={kpiMetricsLoading || teamTableLoading || merchantTableLoading || driverTableLoading}
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          dateFormat="yyyy-MM-dd"
          className="date-picker"
          disabled={kpiMetricsLoading || teamTableLoading || merchantTableLoading || driverTableLoading}
        />
      </div>

      <div className="panel-container">
        <div className="pie-panel">
          <CustomMultiSelect
            label="Exclude Merchant"
            options={merchantOptions}
            selectedOptions={selectedMerchants}
            setSelectedOptions={setSelectedMerchants}
          />
          <div className="pie-panel-chart">
            {kpiMetricsLoading && (
              <Loading />
            )}
            <CustomPieChartPerformance
              value={kpiMetrics.total_deliveries}
              value_label={kpiMetrics.total_deliveries}
              max={kpiMetrics.total_deliveries + kpiMetrics.total_pickups}
              max_label={kpiMetrics.total_deliveries + kpiMetrics.total_pickups}
              title="Total Deliveries"
            />
          </div>
          <div className="pie-panel-chart">
            {kpiMetricsLoading && (
              <Loading />
            )}
            <CustomPieChartPerformance
              value={kpiMetrics.ota_percentage}
              value_label={kpiMetrics.ota_percentage + "%"}
              max={100}
              max_label="100%"
              title="OTA %"
            />
          </div>
          <div className="pie-panel-chart">
            {kpiMetricsLoading && (
              <Loading />
            )}
            <CustomPieChartPerformance
              value={kpiMetrics.over_30mins_late_percentage}
              value_label={kpiMetrics.over_30mins_late_percentage + "%"}
              max={100}
              max_label="100%"
              title="Over 30min Late %"
            />
          </div>
        </div>
        <div className="panel">
          <div className="panel-dropdowns">
            <CustomMultiSelect
              label="Exclude Team"
              options={teamOptions}
              selectedOptions={selectedTeams}
              setSelectedOptions={setSelectedTeams}
            />
            <CustomMultiSelect
              label="Exclude Driver"
              options={driverOptions}
              selectedOptions={selectedDrivers}
              setSelectedOptions={setSelectedDrivers}
            />
          </div>

          <div className="panel-table">
            <CustomTables
              handlePageChange={loadTableData}
              type={"team"}
              data={kpiMetricsByTeam}
              page={teamTablePage}
              total={teamTableTotal}
              loading={teamTableLoading}
            />
          </div>

          <div className="panel-table">
            <CustomTables
              handlePageChange={loadTableData}
              type={"merchant"}
              data={kpiMetricsByMerchant}
              page={merchantTablePage}
              total={merchantTableTotal}
              loading={merchantTableLoading}
            />
          </div>
        </div>
        <div className="panel">
          <CustomMultiSelect
            label="---"
            options={driverOptions}
            selectedOptions={selectedDrivers}
            setSelectedOptions={setSelectedDrivers}
          />
          <div className="panel-table">
            <CustomTables
              handlePageChange={loadTableData}
              data={kpiMetricsByDriver}
              page={driverTablePage}
              total={driverTableTotal}
              loading={driverTableLoading}
            />
          </div>

          <div className="metric-grid">
            {kpiMetricsLoading && (
              <Loading />
            )}
            <div className="metric">
              <div className="metric-title">Under 5min Late</div>
              <div className="metric-value">
                {kpiMetrics.under_5_mins_late
                  ? kpiMetrics.under_5_mins_late
                  : 0}
              </div>
            </div>
            <div className="metric">
              <div className="metric-title">Late Orders</div>
              <div className="metric-value">
                {kpiMetrics.late_deliveries ? kpiMetrics.late_deliveries : 0}
              </div>
            </div>
            <div className="metric">
              <div className="metric-title">Late to Pickup</div>
              <div className="metric-value">
                {kpiMetrics.late_pickups ? kpiMetrics.late_pickups : 0}
              </div>
            </div>
            <div className="metric">
              <div className="metric-title">Avg. per Store</div>
              <div className="metric-value">
                {kpiMetrics.average_per_store
                  ? kpiMetrics.average_per_store
                  : 0}
              </div>
            </div>

            <div className="metric-pie-panel-chart metric-pie">
              <CustomPieChartPerformance
                value={kpiMetrics.late_percentage_pickups}
                value_label={kpiMetrics.late_percentage_pickups + "%"}
                max={100}
                max_label="100%"
                title="Late % Pickup"
              />
            </div>
            <div className="metric">
              <div className="metric-title">Over 30min Late</div>
              <div className="metric-value">
                {kpiMetrics.orders_over_30mins_late
                  ? kpiMetrics.orders_over_30mins_late
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
