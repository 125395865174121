import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/DeliveryKpiDash.css";
import KpiCard from "../components/KpiCard";
import DeliveryChart from "../components/DeliveryChart";
import { AuthContext } from "./AuthProvider";

export const DeliveryKpiDash = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dailyKpis, setDailyKpis] = useState(null);
  const [sevenDayKpis, setSevenDayKpis] = useState(null);
  const [monthlyKpis, setMonthlyKpis] = useState(null);
  const [quarterlyKpis, setQuarterlyKpis] = useState(null);
  const { user } = useContext(AuthContext);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      navigate("/");
    } else {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        fetchKpiData(token);
      } else if (startDate && endDate) {
        fetchKpiData(token);
      }
    }
  }, [startDate, endDate, navigate]); 


  const fetchKpiData = async (token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "X-Tenant-ID": user.tenant_id,
      };

      console.log("Fetching KPI data...");

      const [
        dailyResponse,
        sevenDayResponse,
        monthlyResponse,
        quarterlyResponse,
      ] = await Promise.all([
        fetch(process.env.REACT_APP_API_URL + "/daily_kpis", { headers }),
        fetch(process.env.REACT_APP_API_URL + "/seven_day_kpis", { headers }),
        fetch(process.env.REACT_APP_API_URL + "/monthly_kpis", { headers }),
        fetch(process.env.REACT_APP_API_URL + "/quarterly_kpis", { headers }),
      ]);

      if (
        dailyResponse.ok &&
        sevenDayResponse.ok &&
        monthlyResponse.ok &&
        quarterlyResponse.ok
      ) {
        const dailyData = await dailyResponse.json();
        const sevenDayData = await sevenDayResponse.json();
        const monthlyData = await monthlyResponse.json();
        const quarterlyData = await quarterlyResponse.json();

        setDailyKpis(dailyData);
        setSevenDayKpis(sevenDayData);
        setMonthlyKpis(monthlyData);
        setQuarterlyKpis(quarterlyData);
      } else {
        console.error("Failed to fetch KPI data");
      }
    } catch (error) {
      console.error("Error fetching KPI data:", error);
    }
  };

  return (
    <div>
      <div className="dashboard-content">
        <h1>Delivery KPI Dashboard</h1>
        <p>Company: {user.company_name}</p>

        <div className="date-picker-container">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
          />
        </div>

        <div className="kpi-grid">
          <KpiCard
            title="Deliveries Yesterday"
            value={!dailyKpis ? "Loading...": !dailyKpis.deliveries_yesterday ? "N/A" : dailyKpis.deliveries_yesterday}
            change={
              dailyKpis?.deliveries_yesterday_change != null
                ? `${dailyKpis.deliveries_yesterday_change.toFixed(1)}%`
                : "N/A"
            }
            description="from Yesterday"
            isPositive={dailyKpis?.deliveries_yesterday_change > 0}
            tooltip="Total number of deliveries completed yesterday."
          />
          <KpiCard
            title="OTA Yesterday"
            value={
              dailyKpis === null
              ? "Loading..." : dailyKpis.ota_yesterday != null
                ? `${dailyKpis.on_time_yesterday.toFixed(2)}%`
                : "N/A"
            }
            tooltip="On-Time Arrival percentage for yesterday's deliveries."
          />
          <KpiCard
            title="DpD Last 7 Days"
            value={sevenDayKpis?.dpd_last_7_days ?? "Loading..."}
            change={
              sevenDayKpis?.dpd_last_7_days_change != null
                ? `${sevenDayKpis.dpd_last_7_days_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous 7 days"
            isPositive={sevenDayKpis?.dpd_last_7_days_change > 0}
            tooltip="Average Deliveries per Day over the last 7 days."
          />
          <KpiCard
            title="Active Merchants"
            value={!dailyKpis ? "Loading...": !dailyKpis.active_merchants ? "N/A" : dailyKpis.active_merchants}
            change={
              dailyKpis?.active_merchants_change != null
                ? `${dailyKpis.active_merchants_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous day"
            isPositive={dailyKpis?.active_merchants_change > 0}
            tooltip="Number of merchants who had at least one delivery yesterday."
          />
          <KpiCard
            title="DpM Yesterday"
            value={dailyKpis?.dpm_yesterday ?? "Loading..."}
            change={
              dailyKpis?.dpm_yesterday_change != null
                ? `${dailyKpis.dpm_yesterday_change.toFixed(1)}%`
                : "N/A"
            }
            description="from Yesterday"
            isPositive={dailyKpis?.dpm_yesterday_change > 0}
            tooltip="Average Deliveries per Merchant yesterday."
          />
          <KpiCard
            title="Deliveries this Month"
            value={!monthlyKpis ? "Loading...": !monthlyKpis.deliveries_this_month ? "N/A" : monthlyKpis.deliveries_this_month}
            change={
              monthlyKpis?.deliveries_this_month_change != null
                ? `${monthlyKpis.deliveries_this_month_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous month"
            isPositive={monthlyKpis?.deliveries_this_month_change > 0}
            tooltip="Total deliveries completed in the current month to date."
          />
          <KpiCard
            title="OTA Last 7 Days"
            value={
              sevenDayKpis?.ota_last_7_days != null
                ? `${(sevenDayKpis.ota_last_7_days * 100).toFixed(2)}%`
                : !sevenDayKpis ? "Loading..." : "N/A"
            }
            tooltip="Average On-Time Arrival percentage for the last 7 days."
          />
          <KpiCard
            title="DpD Last 28 Days"
            value={!monthlyKpis ? "Loading...": !monthlyKpis.dpd_last_28_days ? "N/A" : monthlyKpis.dpd_last_28_days}
            change={
              monthlyKpis?.dpd_last_28_days_change != null
                ? `${monthlyKpis.dpd_last_28_days_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous 28 days"
            isPositive={monthlyKpis?.dpd_last_28_days_change > 0}
            tooltip="Average Deliveries per Day over the last 28 days."
          />
          <KpiCard
            title="Active Merchants Last Month"
            value={!monthlyKpis ? "Loading...": !monthlyKpis.active_merchants_this_month ? "N/A" : monthlyKpis.active_merchants_this_month}
            change={
              monthlyKpis?.active_merchants_this_month_change != null
                ? `${monthlyKpis.active_merchants_this_month_change.toFixed(
                    1
                  )}%`
                : "N/A"
            }
            description="from previous month"
            isPositive={monthlyKpis?.active_merchants_this_month_change > 0}
            tooltip="Number of merchants who had at least one delivery last month."
          />
          <KpiCard
            title="DpM This Month"
            value={!monthlyKpis ? "Loading...": !monthlyKpis.dpm_this_month ? "N/A" : monthlyKpis.dpm_this_month}
            change={
              monthlyKpis?.dpm_this_month_change != null
                ? `${monthlyKpis.dpm_this_month_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous month"
            isPositive={monthlyKpis?.dpm_this_month_change > 0}
            tooltip="Average Deliveries per Merchant for the current month."
          />
          <KpiCard
            title="Deliveries this Quarter"
            value={!quarterlyKpis ? "Loading...": !quarterlyKpis.deliveries_this_quarter ? "N/A" : quarterlyKpis.deliveries_this_quarter}
            change={
              quarterlyKpis?.deliveries_this_quarter_change != null
                ? `${quarterlyKpis.deliveries_this_quarter_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous quarter (adjusted)"
            isPositive={quarterlyKpis?.deliveries_this_quarter_change > 0}
            tooltip="Total deliveries completed in the current quarter to date, compared to the adjusted previous quarter."
          />
          <KpiCard
            title="OTA Last 28 Days"
            value={
              monthlyKpis?.ota_last_28_days != null
                ? `${(monthlyKpis.ota_last_28_days * 100).toFixed(2)}%`
                : !monthlyKpis ? "Loading..." : "N/A"
            }
            tooltip="Average On-Time Arrival percentage for the last 28 days."
          />
          <KpiCard
            title="DpD Last 84 Days"
            value={quarterlyKpis?.dpd_last_84_days ?? "Loading..."}
            change={
              quarterlyKpis?.dpd_last_84_days_change != null
                ? `${quarterlyKpis.dpd_last_84_days_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous 84 days"
            isPositive={quarterlyKpis?.dpd_last_84_days_change > 0}
            tooltip="Average Deliveries per Day over the last 84 days."
          />
          <KpiCard
            title="Active Merchants Last Quarter"
            value={!quarterlyKpis ? "Loading...": !quarterlyKpis.active_merchants_this_quarter ? "N/A" : quarterlyKpis.active_merchants_this_quarter}
            change={
              quarterlyKpis?.active_merchants_this_quarter_change != null
                ? `${quarterlyKpis.active_merchants_this_quarter_change.toFixed(
                    1
                  )}%`
                : "N/A"
            }
            description="from previous quarter"
            isPositive={quarterlyKpis?.active_merchants_this_quarter_change > 0}
            tooltip="Number of merchants who had at least one delivery last quarter."
          />
          <KpiCard
            title="DpM This Quarter"
            value={quarterlyKpis?.dpm_this_quarter ?? "Loading..."}
            change={
              quarterlyKpis?.dpm_this_quarter_change != null
                ? `${quarterlyKpis.dpm_this_quarter_change.toFixed(1)}%`
                : "N/A"
            }
            description="from previous quarter"
            isPositive={quarterlyKpis?.dpm_this_quarter_change > 0}
            tooltip="Average Deliveries per Merchant for the current quarter."
          />
        </div>

        <div className="charts-grid">
          <DeliveryChart
            title="Deliveries Over Time"
            dataKey="deliveries"
            endpoint="/deliveries_over_time"
            startDate={startDate}
            endDate={endDate}
          />
          <DeliveryChart
            title="OTA Over Time"
            dataKey="ota_percentage"
            endpoint="/ota_over_time"
            startDate={startDate}
            endDate={endDate}
          />
          <DeliveryChart
            title="Deliveries per Merchant Over Time"
            dataKey="dpmo"
            endpoint="/dpmo_over_time"
            startDate={startDate}
            endDate={endDate}
          />
          <DeliveryChart
            title="Deliveries this Quarter"
            dataKey="deliveries"
            endpoint="/deliveries_this_quarter"
            startDate={startDate}
            endDate={endDate}
          />
          <DeliveryChart
            title="Active Merchants"
            dataKey="active_merchants"
            endpoint="/active_merchants_over_time"
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </div>
  );
};
