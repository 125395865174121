import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import "../../styles/Pagination.css";

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPaginationRange = () => {
        const delta = 2; // Number of pages to show around the current page
        const range = [];
        const start = Math.max(2, currentPage - delta);
        const end = Math.min(totalPages - 1, currentPage + delta);
    
        // Add the first page and last page
        range.push(1);

        // add the ellipsis if the start is greater than 2
        if (start > 2) {
          range.push("...");
        }

        for (let i = start; i <= end; i++) {
          range.push(i);
        }
        if (end < totalPages - 1) {
          range.push("...");
        }
        if (totalPages > 1) {
          range.push(totalPages);
        }
        return range;
      };
    
      const handlePageClick = (page) => {
        if (page === "...") return;
        onPageChange(page);
      };
    
    const paginationRange = getPaginationRange();
    
    return (
        <div className="pagination">
            <button
                className="pagination-prev"
                onClick={() => {handlePageClick(currentPage - 1)}}
                disabled={currentPage === 1}
            >
                <ChevronLeftIcon className="pagination-chevron"/>
            </button>
            {paginationRange.map((pageNumber) => (
                <button
                    key={pageNumber}
                    className={`${
                        pageNumber === currentPage ? "pagination-active-button" : "pagination-button"
                    }`}
                    onClick={() => {handlePageClick(pageNumber)}}
                >
                    {pageNumber}
                </button>
            ))}
            <button
                className="pagination-next"
                onClick={() => {handlePageClick(currentPage + 1)}}
                disabled={currentPage === totalPages}
            >
                <ChevronRightIcon className="pagination-chevron" />
            </button>
        </div>
    );
};
