import { useNavigate } from "react-router-dom";

export const fetchUserDetails = async (token, navigate, handleLogin, handleLogout) => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + '/auth/user', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const userData = await response.json();
      handleLogin(userData);

    } else {
      console.error('Failed to fetch user details:', response);
      handleLogout();
      localStorage.removeItem('accessToken');
      navigate('/');
    }
  } catch (error) {
    console.error('Failed to fetch user details:', error);
    handleLogout();
    localStorage.removeItem('accessToken');
    navigate('/');
  }
};