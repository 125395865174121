import React from "react";
import "../styles/Home.css";
import logo from "../assets/Analytiq logo A.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import { Login } from "../components/home/Login";
import { Register } from "../components/home/Register";

export const Home = () => {
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <div className="home-container">
      <img src={logo} alt="Logo" className="home-logo" />

      <div className="home-content-container">
        <div className="home-auth-forms">
          <Login />
          <Register />
        </div>
        <div className="home-text-container">
          <h1>Welcome to AnalytIQ</h1>
          <p>
            Introducing AnalytIQ: where Analytics meets IQ to unlock the full
            potential of your business through data-driven insights. In today's
            fast-paced world, the ability to make informed decisions quickly is
            what sets successful businesses apart. AnalytIQ is at the forefront
            of this revolution, offering a cutting-edge platform designed to
            transform complex data into actionable intelligence.<br/><br/> Our mission is
            simple yet ambitious: to empower businesses of all sizes with the
            tools and insights needed to navigate the complexities of the
            market, optimize operations, and drive growth. By combining advanced
            analytics with a keen understanding of business intelligence,
            AnalytIQ delivers bespoke solutions that are not just data-rich but
            also intuitively designed for maximum impact.<br/><br/> Whether you're looking
            to refine your strategic direction, enhance operational
            efficiencies, or understand your customers better, AnalytIQ provides
            the clarity and foresight necessary to make confident decisions.
            With AnalytIQ, your business isn't just reacting to the market;
            you're anticipating it, leading it, and reshaping it.<br/><br/> Welcome to the
            future of business intelligence. Welcome to AnalytIQ.
          </p>
        </div>
      </div>
    </div>
  );
};
