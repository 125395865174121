import { useState } from "react";
import { createContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  
    const handleLogout = () => {
      localStorage.removeItem("accessToken");
      setUser(null);
    };

    const handleLogin = (user) => {
        setUser(user);
    }
  
    const [user, setUser] = useState(null);
  
  return (
    <AuthContext.Provider value={{ user, handleLogin, handleLogout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};