import { useNavigate } from 'react-router-dom';
import '../styles/Dashboard.css';
import { useContext } from 'react';
import { AuthContext } from './AuthProvider';


export const Reports = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
      <div className="dashboard-content">
        <h1>Select a Report</h1>
        <p>Company: {user.company_name}</p>

        <div className="dashboard-grid">
          <div className="dashboard-card" onClick={() => handleNavigate('/delivery-kpi')}>
            <h2>Delivery KPI Dashboard</h2>
            <p>View delivery performance metrics</p>
          </div>
          <div className="dashboard-card" onClick={() => handleNavigate('/business-summary')}>
            <h2>Business Summary Dashboard</h2>
            <p>Analyze business performance</p>
          </div>
          <div className="dashboard-card" onClick={() => handleNavigate('/performance')}>
            <h2>Performance Dashboard</h2>
            <p>View performance metrics</p>
          </div>
        </div>
      </div>
  );
}
