import { callRegister } from "../../api/auth";
import { object, string } from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/16/solid";

let loginSchema = object({
    company_name: string().required("Company name is required"),
    name: string().required("Name is required"),
    email: string().email("Please provide a valid email").required("Email is required"),
    password: string().required("Password is required"),
    timezone: string().required("Timezone is required")
});

let timezones = Intl.supportedValuesOf('timeZone');


export const Register = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(loginSchema),
      })
    
    const handleRegister = async (data) => {
        const payload = {
            company_name: data.company_name,
            username: data.name,
            email: data.email,
            password: data.password,
            timezone: data.timezone,
            role: "superAdmin"
        }
        try {
            await callRegister(payload, navigate, setError);
        } catch (error) {
            console.error("Failed to register:", error);
            setError("Something went wrong. Please try again.");
        }
    }

    return (
        <div className="auth-dropdown-container">
            <button className="auth-dropdown-button" onClick={() => setOpen(!open)}>
                <ChevronRightIcon className="auth-dropdown-icon" />
                <p>Register</p>
            </button>

            <div className={`auth-form-container ${open ? 'open' : 'closed'}`}>
                <form className="auth-form" onSubmit={handleSubmit(handleRegister)}>
                    {error && <p className="error-message">{error}</p>}
                    <input
                        type="text"
                        placeholder="Company Name"
                        {...register("company_name")}
                    />
                    <p className="error-message">{errors.company_name?.message}</p>
                    <input
                        type="text"
                        placeholder="Name"
                        {...register("name")}
                    />
                    <p className="error-message">{errors.name?.message}</p>
                    <input
                        type="text"
                        placeholder="Email"
                        {...register("email")}
                    />
                    <p className="error-message">{errors.email?.message}</p>
                    <input
                        type="password"
                        placeholder="Password"
                        {...register("password")}
                    />
                    <p className="error-message">{errors.password?.message}</p>
                    <select {...register("timezone")}>
                        {timezones.map((timezone) => (
                            <option key={timezone} value={timezone}>
                                {timezone}
                            </option>
                        ))}
                    </select>
                    <p className="error-message">{errors.timezone?.message}</p>
                    <button type="submit">
                        Register
                    </button>
                </form>
            </div>
        </div>
    )
}
