import "react-datepicker/dist/react-datepicker.css";
import "../../styles/BusinessSummaryDash.css";
import "../../styles/PerformanceDash.css";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

export const CustomPieChartPerformance = ({
  value,
  value_label,
  max,
  max_label,
  title,
}) => {
  const data = [
    { value: value }, // Percentage of completion (e.g., 60% of the gauge filled)
    { value: max - value }, // Remaining value to complete 100%
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const cx = 150;
  const cy = 100;
  const iR = 80;
  const oR = 100;

  return (
    <div>
        <PieChart width={300} height={160}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              value={title}
              position="centerBottom"
              className="label-top"
              fontSize="16"
              fill="#6B8E23"
            />
            <Label
              value={value_label}
              position="center"
              className="label-bottom"
              fontSize="30"
              fill="#6B8E23"
            />
          </Pie>
        </PieChart>
      <div className="range-labels">
        <span className="range-left">0</span>
        <span className="range-right">{max_label}</span>
      </div>
    </div>
  );
};
