import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../assets/Analytiq logo A.png';
import '../styles/TopBar.css';

function TopBar({ user, onLogout }) {
  const navigate = useNavigate();
  return (
    <div className="top-bar">
      <img src={logo} alt="Logo" className="logo" />
      <nav className="navigation">
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/reports">Reports</Link>
        <Link to="/profile">Profile</Link>
        
      </nav>
      <div className="user-info">
        <span className="username">{user?.username || 'User'}</span>
        <span className="email">{user?.email || 'Email'}</span>
      </div>
      <button className="logout-btn" onClick={() => {onLogout(); navigate("/")}}>
        Logout
      </button>
    </div>
  );
}

export default TopBar;
