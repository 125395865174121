import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { AuthProvider } from "./pages/AuthProvider";
import { Reports } from "./pages/Reports";
import { DeliveryKpiDash } from "./pages/DeliveryKpiDash";
import { BusinessSummaryDash } from "./pages/BusinessSummaryDash";
import { Performance } from "./pages/Performance";
import { Integrations } from "./pages/Integrations";
import { Layout } from "./pages/Layout";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/dashboard" element={<Integrations />} />
              <Route path="/delivery-kpi" element={<DeliveryKpiDash />} />
              <Route path="/business-summary" element={<BusinessSummaryDash />} />
              <Route path="/performance" element={<Performance />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
