import React, { useState, useEffect, useRef } from 'react';
import '../../styles/Common.css';
import { ChevronDownIcon } from '@heroicons/react/16/solid';


export const CustomMultiSelect = ({ label, options, selectedOptions, setSelectedOptions }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  const toggleDropdown = (event) => {
    event.stopPropagation() 
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="custom-multi-select" ref={dropdownRef}>
      <div className="dropdown-header" >
        <button className="dropdown-button" onClick={toggleDropdown}>
          {label}
          <ChevronDownIcon className="dropdown-icon" />
        </button>
        
      </div>

      {isDropdownOpen && (
        <div className="dropdown-list">
            {options.length === 0 ? <p className='dropdown-option' style={{color: 'black'}}>No options available</p> : 
            <>
          {options.map((option, index) => (
            <label key={index} className="dropdown-option">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionChange(option)}
              />
              {option.label}
            </label>
          ))}</>}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;