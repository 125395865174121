import { set } from "date-fns/set";

export const callLogin = async (payload, navigate, setError) => {
    console.log(process.env.REACT_APP_API_URL);
    const response = await fetch(process.env.REACT_APP_API_URL + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200) {
        // Save the access token to local storage
        localStorage.setItem('accessToken', data.access_token);
        navigate('/dashboard'); // Redirect to the dashboard
      } else {
        setError(data.message);
      }

     
}

export const callRegister = async (payload, navigate, setError) => {
    const response = await fetch(process.env.REACT_APP_API_URL + '/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200) {
        // Save the access token to local storage
        localStorage.setItem('accessToken', data.access_token);
        navigate('/dashboard'); // Redirect to the dashboard
      } else {
            setError(data.message);
        }
    }


export const callAuthenticateIntegration = async (payload, navigate, setError) => {

    const response = await fetch(process.env.REACT_APP_API_URL + '/auth/authenticate_integration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      
      if (response.status === 200) {
        return true;
      } else {
        console.log(data);
        if (data.error) {
          setError(data.error);
        } else if (data.message) {
          setError(data.message);
        } else if (data.msg && data.msg === "Token has expired") {
          localStorage.removeItem('accessToken');
          navigate('/');
        } else {
          setError("An error occurred. Please try again later.");
        }
        return false;
      }

}
