export const fetchBusinessSummaryData = async (startDate, endDate, page, tenantId) => {
    const token = localStorage.getItem('accessToken');

    const start_date = startDate.toISOString().split('T')[0];
    const end_date = endDate.toISOString().split('T')[0];

    if (!token || !tenantId) {
      console.error("Missing token or tenant ID");
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/business_summary?start_date=${start_date}&end_date=${end_date}&page=${page ? page : 1}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Tenant-ID': tenantId,
        },
      });

      if (!response.ok) {
        console.error(`Error ${response.status}: ${response.statusText}`);
        return;
      }

      const result = await response.json();
      return result
    } catch (error) {
      console.error('Error fetching data:', error);
      return null
    }
  };

export const fetchIncomeDelivieriesOverTime = async (startDate, endDate, tenantId) => {
    const token = localStorage.getItem('accessToken');

    const start_date = startDate.toISOString().split('T')[0];
    const end_date = endDate.toISOString().split('T')[0];

    if (!token || !tenantId) {
      console.error("Missing token or tenant ID");
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/income_deliveries_over_time?start_date=${start_date}&end_date=${end_date}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Tenant-ID': tenantId,
        },
      });

      if (!response.ok) {
        console.error(`Error ${response.status}: ${response.statusText}`);
        return;
      }

      const result = await response.json();
      return result

    } catch (error) {
      console.error('Error fetching data:', error);
      return null
    }
  }