import { callLogin } from "../../api/auth";
import { object, string } from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/16/solid";

let loginSchema = object({
    email: string().email("Please provide a valid email").required("Email is required"),
    password: string().required("Password is required"),
});

export const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(loginSchema),
      })
    
    const handleLogin = async (data) => {
        console.log(data)
        const payload = { email: data.email, password: data.password };
        try {
            console.log(process.env.REACT_APP_API_URL);
            await callLogin(payload, navigate, setError);
        } catch (error) {
            console.error("Failed to login:", error);
            setError("Something went wrong. Please try again.");
        }
    }

    return (
        <div className="auth-dropdown-container">
            <button className="auth-dropdown-button" onClick={() => setOpen(!open)}>
                <ChevronRightIcon className="auth-dropdown-icon" />
                <p>Login</p>
            </button>

            <div className={`auth-form-container ${open ? 'open' : 'closed'}`}>
                <form className="auth-form" onSubmit={handleSubmit(handleLogin)}>
                    {error && <p className="error-message">{error}</p>}
                    <input
                        type="text"
                        placeholder="Email"
                        {...register("email")}
                    />
                    <p className="error-message">{errors.email?.message}</p>
                    <input
                        type="password"
                        placeholder="Password"
                        {...register("password")}
                    />
                    <span className="error-message">{errors.password?.message}</span>
                    <button type="submit">
                        Login
                    </button>
                </form>
            </div>
            
        </div>
    );
}