import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthProvider";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/BusinessSummaryDash.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import { Pagination } from "../components/common/Pagination";

import {
  fetchBusinessSummaryData,
  fetchIncomeDelivieriesOverTime,
} from "../api/business_summary";
import { formatTableData } from "../utils/FormatTableData";
import { Loading } from "../components/common/Loading";


export const BusinessSummaryDash = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(1);

  const [summaryData, setSummaryData] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [summaryTotal, setSummaryTotal] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [incomeDeliveriesData, setIncomeDeliveriesData] = useState([]);
  const [incomeDeliveriesLoading, setIncomeDeliveriesLoading] = useState(true);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    setPage(1);
    setSummaryLoading(true);
    setIncomeDeliveriesLoading(true);
    console.log(user);

    fetchBusinessSummaryData(startDate, endDate, 1, user.tenant_id).then((data) => {
      setSummaryData(data.summary);
      setChartData(data.orders_by_merchants);
      setSummaryTotal(data.count);
      setSummaryLoading(false);
      console.log(data);
    });

    fetchIncomeDelivieriesOverTime(startDate, endDate, user.tenant_id).then((data) => {
      console.log(data);
      // convert values to numbers
      const sanitizedData = data.map((item) => {
        return {
          date: item.date,
          income: parseFloat(item.income),
          deliveries: parseFloat(item.deliveries),
        };
      });
      setIncomeDeliveriesData(sanitizedData);
      setIncomeDeliveriesLoading(false);
    });
  }, [startDate, endDate]);

  const handlePageChange = (page) => {
    setPage(page);
    setSummaryLoading(true);
    fetchBusinessSummaryData(startDate, endDate, page, user.tenant_id).then((data) => {
      setSummaryData(data.summary);
      setSummaryTotal(data.count);
      setChartData(data.orders_by_merchants);
      setSummaryLoading(false);
    });

    
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8e53",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884d8",
    "#82ca9d",
    "#ffc658",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.65;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent < 0.05) {
      return;
    }
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div>
      <div className="dashboard-content">
        <h1>Business Summary</h1>
        <div className="date-picker-container">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
            className="date-picker"
          />
        </div>

        <div className="summary-table">
          {summaryLoading && (
            <Loading />
          )}
          <table>
            <thead>
              <tr>
                <th>Merchant</th>
                <th>Orders</th>
                <th>Collect</th>
                <th>Deliver</th>
                <th>% Deliveries</th>
                <th>Order Total</th>
                <th>Avg. Order</th>
                <th>Income</th>
                <th>COGS</th>
                <th>Gross Profit</th>
                <th>Income / Order</th>
                <th>COGS / Order</th>
              </tr>
            </thead>
            <tbody>
              {summaryData.length > 0 ? summaryData.map((row, index) => (
                <tr key={index}>
                  <td>{formatTableData(row.merchant, false, false)}</td>
                  <td>{formatTableData(row.orders, false, false)}</td>
                  <td>{formatTableData(row.collections, false, false)}</td>
                  <td>{formatTableData(row.deliveries, false, false)}</td>
                  <td>
                    {formatTableData(row.percentage_deliveries, false, true)}
                  </td>
                  <td>{formatTableData(row.order_value, true, false)}</td>
                  <td>
                    {formatTableData(row.average_order_value, true, false)}
                  </td>
                  <td>{formatTableData(row.income, true, false)}</td>
                  <td>{formatTableData(row.cogs, true, false)}</td>
                  <td>{formatTableData(row.gross_profit, true, false)}</td>
                  <td>
                    {formatTableData(row.average_income_per_order, true, false)}
                  </td>
                  <td>
                    {formatTableData(
                      row.average_driver_cogs_per_order,
                      true,
                      false
                    )}
                  </td>
                </tr>
              )) : <tr><td colSpan="12">No data available</td></tr>}
            </tbody>
          </table>
          
        </div>
        <Pagination
            currentPage={page}
            totalPages={Math.ceil(summaryTotal / 20)}
            onPageChange={handlePageChange}
          />

        <div className="charts-container">
          <div className="line-chart">
            {incomeDeliveriesLoading && (
              <Loading />
            )}
            <h3>Income and Deliveries Over Time</h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={incomeDeliveriesData}
                width={500}
                height={400}
                margin={{ top: 0, right: 50, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="income"
                  stroke="#8884d8"
                  dot={false}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="deliveries"
                  stroke="#82ca9d"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="pie-chart">
            {summaryLoading && (
              <Loading />
            )}
            <h3>Orders by Merchant</h3>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={140}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
