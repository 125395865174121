import TopBar from "../components/TopBar";
import { useEffect, useContext } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { fetchUserDetails } from "../api/user";
import "../styles/LayoutStyles.css";
import { AuthContext } from "./AuthProvider";

export const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === "/";
  const { user, handleLogin, handleLogout } = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token && isLoginPage) {
      navigate("/dashboard");
    } else if (!token) {
      navigate("/");
    } else {
      fetchUserDetails(token, navigate, handleLogin, handleLogout);
    }
  }, [navigate]);


  return (
    <div className="layout-container">
      {(user && !isLoginPage) ? <><TopBar user={user} onLogout={handleLogout} />
      <div className="layout-content-container">
        <Outlet />
      </div></> : isLoginPage &&
        <Outlet />
      }
    </div>
  );
};
