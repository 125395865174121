import "../../styles/BusinessSummaryDash.css";


export const Loading = () => {
    return (
        <div className="loader">
            <div className="loader-spinner"></div>
        </div>
    );
}

export const LoadingIcon = () => {
    return (
        <div className="loader">
            <div className="loader-spinner-icon"></div>
        </div>
    );
}