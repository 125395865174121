import { useNavigate } from "react-router-dom";
import "../styles/Dashboard.css";
import { useContext, useState } from "react";
import { AuthContext } from "./AuthProvider";
import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import tookan from "../assets/tookan2.png";
import yelo from "../assets/yelo.png";
import infraspeak from "../assets/infraspeak.png";

import { callAuthenticateIntegration } from "../api/auth";
import { LoadingIcon } from "../components/common/Loading";

export const Integrations = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [viewing, setViewing] = useState("");
  const [tookanKey, setTookanKey] = useState(user.tookan_api_key);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Syncing tasks with Tookan API");
    setLoading(true);


    const payload = {
      integration: viewing,
      integration_key: tookanKey,
      user_id: user.id,
      tenant_id: user.tenant_id,
    };

    callAuthenticateIntegration(payload, navigate, setError).then((res) => {
      if (res) {
        console.log("Tasks synced successfully");
        setError("");
        setViewing("");
      }
      setLoading(false);
    });

  };

  console.log(user)


  return (
    <div className="dashboard-content">
      <h1>Welcome, {user.username}</h1>
      <p>
        Explore your data insights effortlessly with our AI-powered chat
        interface.
      </p>
      <p>Company: {user.company_name}</p>

      <div className="integrations-view-wrapper">
        {viewing === "" ? (
          <>
            <div>
              <div className="integrations-card" onClick={() => setViewing("tookan")}>
                <img src={tookan} alt="Tookan" />
                <p>Tookan</p>
              </div>
            </div>
            <div>
              <div className="integrations-card">
                <img src={yelo} alt="Yelo" />
                <p>Yelo (WIP)</p>
              </div>
            </div>
            <div>
              <div className="integrations-card">
                <img src={infraspeak} alt="Infraspeak" className="infraspeak-img" />
                <p>Infraspeak (WIP)</p>
              </div>
            </div>
          </>
        ) : viewing === "tookan" && (
              <div className="integrations-card-active">
                <button className="back-icon-button" onClick={() => {setError(""); setViewing("")}}>
                  <ChevronLeftIcon className="back-icon" />
                </button>
                <h2>Tookan Integration Trial - Sync Yesterday's Tasks</h2>
                <h3>Link to Existing Tenant and Enter an API Key</h3>
                <p>Tenant ID: {user.tenant_id}</p>
                <p>API Key</p>
                {error && <p className="error-message">{error}</p>}
                <form className="integrations-form">
                    <input
                        type="text"
                        className={`${error ? "input-error" : ""}`}
                        value={tookanKey}
                        onChange={(e) => setTookanKey(e.target.value)}
                    />
                    <button disabled={tookanKey === "" || loading} onClick={handleSubmit}>
                      {!loading ? 'Submit': <LoadingIcon />}
                    </button>
                </form>
              </div>
        )}
      </div>
    </div>
  );
};
