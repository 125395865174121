import React, { useState, useRef } from 'react';
import '../styles/KpiCard.css';
import '../styles/Tooltip.css';
import Tooltip from './Tooltip';

function KpiCard({ title, value, change, description, isPositive, tooltip }) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const cardRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({});

  const handleMouseEnter = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: `${rect.top - 80}px`,
        left: `${rect.left + rect.width / 2}px`,
        transform: 'translateX(-50%)'
      });
    }
    setIsTooltipVisible(true);
  };

  return (
    <div 
      className="kpi-card-container"
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <div className="kpi-card">
        <div className="kpi-title">{title}</div>
        <div className="kpi-value">{value}</div>
        <div className={`kpi-change ${isPositive ? 'positive' : 'negative'}`}>
          {change}
        </div>
        <div className="kpi-description">{description}</div>
      </div>
      <Tooltip 
        content={tooltip} 
        isVisible={isTooltipVisible} 
        position={tooltipPosition}
      />
    </div>
  );
}

export default KpiCard;