import { formatTableData } from "../../utils/FormatTableData";
import { Loading } from "../common/Loading";
import { Pagination } from "../common/Pagination";
import "../../styles/PerformanceDash.css";

export const CustomTables = ({ handlePageChange, type, data, page, total, loading }) => {

    let team = (type === "team")
    let merchant = (type === "merchant")
    let fleet = (!team && !merchant)

  const triggerPageChange = (page) => {
    handlePageChange(page, type);
  }

  return (
    <div>
      <div className="performance-table">
        <div className="performance-table-loader-container">
          {loading && (
            <Loading />
          )}
          <table>
            <thead>
              <tr>
                <th>
                  {team
                    ? "Team"
                    : merchant
                    ? "Merchant"
                    : "Fleet"}
                </th>
                <th>Orders</th>
                <th>OTA</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{formatTableData(team ? row.team_id : merchant ? row.job_pickup_name : row.fleet_name, false, false)}</td>
                  <td>{formatTableData(row.total_deliveries, false, false)}</td>
                  <td>{formatTableData(row.on_time_deliveries, false, false)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(total / 5)}
          onPageChange={triggerPageChange}
        />
      </div>
    </div>
  );
};
