import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/Tooltip.css';

const Tooltip = ({ content, isVisible, position }) => {
  if (!isVisible) return null;
  
  return ReactDOM.createPortal(
    <div className="tooltip" style={position}>
      <div className="tooltip-content">{content}</div>
    </div>,
    document.body
  );
};

export default Tooltip;