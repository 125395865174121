import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

function DeliveryChart({ title, dataKey, endpoint, startDate, endDate }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessToken');
      const tenantId = 'OJC3wIzZqQcfmONodiV2U9dzbZ53'; // Replace with the actual tenant ID

      const start_date = startDate.toISOString().split('T')[0];
      const end_date = endDate.toISOString().split('T')[0];

      if (!token || !tenantId) {
        console.error("Missing token or tenant ID");
        return;
      }

      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `${endpoint}?start_date=${start_date}&end_date=${end_date}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Tenant-ID': tenantId,
          },
        });

        if (!response.ok) {
          console.error(`Error ${response.status}: ${response.statusText}`);
          return;
        }

        const result = await response.json();
        
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [startDate, endDate, endpoint]);

  return (
    <div className="chart-container">
      <h3>{title}</h3>
      {data ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey={dataKey} stroke="#20bce6" />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default DeliveryChart;
