import { set } from "date-fns";

export const fetchPerformanceKpiMetrics = async (
  token,
  setKpiMetrics,
  navigate,
  data,
  setLoading,
  tenantId
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/performance_kpi_metrics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant-ID": tenantId,
        },
        body: JSON.stringify(data),
      }
    );
    const response_data = await response.json();
    console.log(response_data);
    setKpiMetrics(response_data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching KPI metrics:", error);
    setLoading(false);
  }
};

export const fetchOptions = async (
  token,
  setMerchantOptions,
  setTeamOptions,
  setDriverOptions,
  navigate,
  start_date,
  end_date,
  tenantId
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/get_options?start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant-ID": tenantId,
        },
      }
    );
    const response_data = await response.json();
    console.log(response_data);
    setMerchantOptions(response_data.merchants);
    setTeamOptions(response_data.teams);
    setDriverOptions(response_data.fleets);
  } catch (error) {
    console.error("Error fetching options:", error);
  }
};

export const fetchKpiMetricsByMerchant = async (
  token,
  setKpiMetrics,
  setTotal,
  navigate,
  start_date,
  end_date,
  page,
  setLoading,
  tenantId
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/performance_kpi_metrics_by_merchant?start_date=${start_date}&end_date=${end_date}&page=${
        page ? page : 1
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant-ID": tenantId,
        },
      }
    );
    const response_data = await response.json();
    console.log(response_data);
    setKpiMetrics(response_data.data);
    setTotal(response_data.total);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching KPI metrics:", error);
  }
};

export const fetchKpiMetricsByTeam = async (
  token,
  setKpiMetrics,
  setTotal,
  navigate,
  start_date,
  end_date,
  page,
  setLoading,
  tenantId
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/performance_kpi_metrics_by_team?start_date=${start_date}&end_date=${end_date}&page=${
        page ? page : 1
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant-ID": tenantId,
        },
      }
    );
    const response_data = await response.json();
    console.log(response_data);
    setKpiMetrics(response_data.data);
    setTotal(response_data.total);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching KPI metrics:", error);
  }
};

export const fetchKpiMetricsByDriver = async (
  token,
  setKpiMetrics,
  setTotal,
  navigate,
  start_date,
  end_date,
  page,
  setLoading,
  tenantId
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/performance_kpi_metrics_by_fleet?start_date=${start_date}&end_date=${end_date}&page=${
        page ? page : 1
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant-ID": tenantId,
        },
      }
    );
    const response_data = await response.json();
    console.log(response_data);
    setKpiMetrics(response_data.data);
    setTotal(response_data.total);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching KPI metrics:", error);
  }
};
